<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'users'}">Users</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Edit User</a></li>
    </ol>
    <h1 class="page-header">Edit User <small>({{ user.role }})</small></h1>
    <panel noButton="true" title="Edit User" variant="panel-default">
      <div v-html="$error.handle(error)"/>
      <form v-if="is_loaded" @submit.prevent="submit">
        <div class="row">
          <div
              :class="{ 'has-feedback has-error': errors.has('first_name')}"
              class="col-md-4 form-group"
          >
            <label>First Name</label>
            <input
                v-model="user.firstname"
                v-validate="'required'"
                class="form-control"
                name="first_name"
                type="text"
            >
            <small
                v-if="errors.has('first_name')"
                class="help-block"
            >
              {{ errors.first('first_name') }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>Middle Name</label>
            <input
                v-model="user.middlename"
                class="form-control"
                type="text"
            >
          </div>
          <div
              :class="{ 'has-feedback has-error': errors.has('last_name')}"
              class="col-md-4 form-group"
          >
            <label>Last name</label>
            <input
                v-model="user.lastname"
                v-validate="'required'"
                class="form-control"
                name="last_name"
                type="text"
            >
            <small
                v-if="errors.has('last_name')"
                class="help-block"
            >
              {{ errors.first('last_name') }}
            </small>
          </div>
          <div
              :class="{ 'has-feedback has-error': errors.has('phone_number')}"
              class="col-md-4 form-group"
          >
            <label>Phone </label>
            <input
                v-model="user.phone_number"
                v-validate="'required|max:15'"
                class="form-control"
                name="phone_number"
                type="text"
            >
            <small
                v-if="errors.has('phone_number')"
                class="help-block"
            >
              {{ errors.first('phone_number') }}
            </small>
          </div>

          <div
              :class="{ 'has-feedback has-error': errors.has('id_number')}"
              class="col-md-4 form-group"
          >
            <label>ID Number </label>
            <input
                v-model="user.id_number"
                v-validate="'required'"
                class="form-control"
                name="id_number"
                type="text"
            >
            <small
                v-if="errors.has('id_number')"
                class="help-block"
            >
              {{ errors.first('id_number') }}
            </small>
          </div>

          <div
              :class="{ 'has-feedback has-error': errors.has('email')}"
              class="col-md-4 form-group"
          >
            <label>Email</label>
            <input
                v-model="user.email"
                v-validate="'required|email'"
                class="form-control"
                name="email"
                type="text"
            >
            <small
                v-if="errors.has('email')"
                class="help-block"
            >
              {{ errors.first('email') }}
            </small>
          </div>
          <div
              :class="{ 'has-feedback has-error': errors.has('role')}"
              class="col-md-4 d-none form-group"
          >
            <label>Role</label>
            <select
                v-model="user.role"
                v-validate="'required'"
                class="form-control"
                name="role"
            >
              <option
                  v-for="(role,index) in roles"
                  :key="index"
                  :value="role.name"
              >
                {{ role.name | unslug }}
              </option>
            </select>
            <small
                v-if="errors.has('role')"
                class="help-block"
            >
              {{ errors.first('role') }}
            </small>
          </div>

          <div class="col-md-12">
            <button class="btn btn-primary">
              <i class="fas fa-save"></i> Save
            </button>
          </div>
        </div>
      </form>
      <loading v-else/>
    </panel>
  </div>
</template>

<script>
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  data() {
    return {
      user: {},
      is_loaded: false,
      error: '',
      roles: [],
    }
  },
  mounted() {
    this.fetch();
    this.fetchRoles();
  },

  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/users/' + this.$route.params.id).then(response => {
        this.user = response.data;
        this.is_loaded = true;
      });
    },
    fetchRoles() {
      this.$axios.get('/api/v1/roles/other').then(response => {
        this.roles = response.data;
        this.loading = false;
      });
    },
    submit() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          let user = this.user;
          user.role = user.role.toLowerCase().replace(' ', '_');
          this.$axios.put('/api/v1/users/' + this.$route.params.id, user).then(() => {
            this.$toastr.s('The users has been updated.', 'Saved');
            this.$router.push({name: 'users.show', params: {id: this.$route.params.id}});
          }).catch(error => {
            this.error = error.response;
          });
        }
      });

    }
  }
}
</script>
